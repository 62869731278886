import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinus,
  faPlus,
  faTrash,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Currency } from "react-intl-number-format/dist/index.js";
import { Fab } from "@mui/material";
import { setBasket } from "../stores/Cart";
import { useDispatch } from "react-redux";
import { useCartRequests } from "../../helpers/CartRequests";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
export default function Item({ item, cart }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [count, setCount] = useState(item.quantity);

  const { decrementCart, incrementCart, extractionCart } = useCartRequests();

  const increment = async (item, count = 1) => {
    const basket = await cart.map((sub) => {
      if (sub.id === item.id) {
        return {
          ...sub,
          quantity: sub.quantity + count,
          total: sub.total + sub.price * count,
        };
      }
      return sub;
    });

    incrementCart(item.productId, count)
      .then((response) => {
        dispatch(setBasket(basket));
        setCount(item.quantity + count);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const decrement = async (item, remove = false, count = 1) => {
    if (remove) {
      const basket = await cart.filter((sub) => {
        return sub.id !== item.id;
      });
      extractionCart(item.productId)
        .then((response) => {
          dispatch(setBasket(basket));
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } else {
      const basket = await cart.map((sub) => {
        if (sub.id === item.id) {
          return {
            ...sub,
            quantity: sub.quantity - count,
            total: sub.total - sub.price * count,
          };
        }
        return sub;
      });
      decrementCart(item.id, count)
        .then((response) => {
          dispatch(setBasket(basket));
          setCount(item.quantity - count);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  };

  const handleChange = async (e) => {
    if (e.target.value < item.quantity) {
      decrement(
        item,
        e.target.value == 0 ? true : false,
        item.quantity - e.target.value
      );
    } else {
      increment(item, e.target.value - item.quantity);
    }
  };

  return (
    <li key={"cart-item-mobile " + item.id}>
      <div className="item" onClick={() => history.push(`/urun/${item.slug}`)}>
        <div className="img">
          <img src={item.image} alt="Görsel" />
        </div>
        <div
          className="name"
          onClick={() => history.push(`/urun/${item.slug}`)}
        >
          <div className="title" style={{color:'black'}}>{item.title}</div>
          {/* <div className="available">
            Mevcut : {item.availableStock > 80 ? "80+" : item.availableStock}
          </div>
          <div className="desc">
            ( <span>x{item.quantity}</span> Koli /{" "}
            <span>x{item.quantity * item.unit2coefficient}</span> Çift )
          </div> */}
        </div>
      </div>
      <div className="price-box">
        <div className="button-list">
          <div className="btn">
            <div className="decrement">
              <FontAwesomeIcon
                icon={count == 1 ? faTrashAlt : faMinus}
                onClick={() => {
                  if (count == 1) {
                    decrement(item, true);
                  } else {
                    decrement(item);
                  }
                }}
              />
            </div>
            <input type={"number"} value={count} />
            <div className="increment">
              <FontAwesomeIcon icon={faPlus} onClick={() => increment(item)} />
            </div>
          </div>
        </div>
        <div className="price">
          <Currency>{item.salePrice}</Currency>
        </div>
      </div>
    </li>
  );
}
